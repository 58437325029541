import { UserAuthService } from 'src/app/core/services/user-auth/user-auth.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/ApiService/apiService';
import { Authenticationservice } from 'src/app/core/services/Auth/authenticationService';
import { HttpHeaders } from '@angular/common/http';
import { Token } from '@angular/compiler/src/ml_parser/lexer';
import { UserProfile } from 'src/app/core/models/user.model';
import { UserService } from 'src/app/core/services/user/user.service';
import { AlertService } from 'src/app/core/services/alert/alert.service';
@Component({
  selector: 'app-registration-user-detail-dialog',
  templateUrl: './registration-user-detail-dialog.component.html',
  styleUrls: ['./registration-user-detail-dialog.component.scss'],
})
export class RegistrationUserDetailDialogComponent
  implements OnInit, OnDestroy {
  userDetailsForm: FormGroup;

  private subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<RegistrationUserDetailDialogComponent>,

    @Inject(MAT_DIALOG_DATA)
    public data: {
      board: string;
      boardId: string;
      grade: string;
      gradeId: string;
      stream?: string;
      streamId: string;
    }
  ) {
    this.userDetailsForm = this.fb.group({
      name: [null, Validators.required],
      email: [null, Validators.required],
      password: [null, Validators.required],
      city: [null, Validators.required],
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onClickNext() {
    let userProfile: UserProfile = {
      name: this.userDetailsForm.get('name').value,
      email: this.userDetailsForm.get('email').value,
      board: this.data.board,
      boardId: this.data.boardId,
      grade: this.data.grade,
      gradeId: this.data.gradeId,
      city: this.userDetailsForm.get('city').value,
      password: this.userDetailsForm.get('password').value,
    };

    if (this.data.stream) {
      userProfile.stream = this.data.stream;
      userProfile.streamId = this.data.streamId
    }

    this.subscriptions.add(
      this.userService.createUserProfile(userProfile).subscribe(
        (res) => {
          const userId = res.userId;
          if (res.userId) {
            this.registrationSuccessHandler(userId);
          }
        },
        (err) => this.alertService.error('Failed to create profile!')
      )
    );
  }

  registrationSuccessHandler(userId: string) {
    this.subscriptions.add(
      this.userService.getUserByUserId(userId).subscribe((user) => {
        if (user) {
          this.router.navigate(['/student', 'home']);
          this.dialogRef.close();
        }
      })
    );
  }

  // onRegisterfinal() {
  //   let formdata = { ...this.data, ...this.userDetailsForm.value };
  //   console.log('ghffhj', formdata);
  //   this.apiService.CreateProfile(formdata, this.data._id).subscribe((res) => {
  //     console.log('FORM DATA', res);
  //     console.log('FORM DATA', this.data._id);
  //     const userid = sessionStorage.setItem('userid', this.data._id);

  //     if (res) {
  //       this.router.navigateByUrl('home');
  //     }
  //     let userDetail = <any>res;
  //     this.authenticate.getUserDetailsFromToken(userDetail.data);
  //   });
  // }
}
