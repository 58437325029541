import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-temsandconditions',
  templateUrl: './temsandconditions.component.html',
  styleUrls: ['./temsandconditions.component.scss']
})
export class TemsandconditionsComponent implements OnInit {

  constructor() {
    window.scrollTo(0, 0);
   }

  ngOnInit(): void {
  }

}
