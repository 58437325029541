import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss'],
})
export class MarketingComponent implements OnInit {
  constructor() {}

  subjects = [
    {
      title: 'Mathematics',
      img: 'assets/illustrations/subjects/maths.png',
      bg: '#99E0FF',
    },
    {
      title: 'Science',
      img: 'assets/illustrations/subjects/science.png',
      bg: '#DDCDFF',
    },
    {
      title: 'Social Science',
      img: 'assets/illustrations/subjects/socialscience.png',
      bg: '#FFF3B0',
    },
    {
      title: 'English',
      img: 'assets/illustrations/subjects/english.png',
      bg: '#DCF896',
    },
  ];

  ngOnInit() {}
}
