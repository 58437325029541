import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EduwebendpointService {
  // host: string = 'http://localhost:3000/';
  host: string = 'https://www.appsophia.com/';
  api = this.host + 'edu/api/';

  user = this.api + 'user/';
  admin = this.api + 'admin/';

  verifyPhioneNumber = this.user + 'verifyPhoneNumber/';
  verifyStudentCode = this.user + 'verifyUserByStudentCode/';
  verifyOtp = this.user + 'verifyOTP/';
  verifyOtpforStudentcode = this.user + 'studentVerifyOTP/';
  createProfiles = this.user + 'createProfile/';
  getMyProfileById = this.user + 'getUser/';

  /**************************************************
   * ADMIN URLS
   *********************************************/
  getAllBoards = this.admin + 'getBoards';
  getGradesByBoardId = this.admin + 'getGrades/';
  getSubjects = this.admin + 'getSubjects/';
  getLessionsBySublectId = this.admin + 'getLessons/';
  getTopics = this.admin + 'getTopics/';
  getStreamsByGradeId = this.admin + 'getStreams/';
  getVideos = this.admin + 'getVideos/';
  getPackageBYgradeIdandStream = this.admin + 'getPackages/';
  getPackagesbySubject = this.admin + 'getPackagesbySubject/';
  getMCQ = this.admin + 'getMCQs/';
  getTimetable = this.admin + 'getTimeTableById/';

  // /admin/getPackages/:gradeId?streamName=streamName

  // *********************************************/

  getAllProfiles = this.user + 'getAllProfiles';
  profileEdit = this.admin + 'activeInactiveAccount/';

  getAllGrades = this.admin + 'getAllGrade'
  getAllStreams = this.admin + 'getAllStreams'
  getAllSubjects = this.admin + 'getAllSubject'
  getAlltopics = this.admin + 'getAllTopic'
  getAllVideos = this.admin + 'getAllVideoLinks'
  getAllLessons = this.admin + 'getAllLesson'
  getAllPackages = this.admin + 'getAllPackages';
  getAllPackageCategories = this.admin + 'getAllPackageCategories';
  getAllTimetables = this.admin + 'getAllTimetable';
  getStreamByGradeId = this.admin + 'getStreams/';
  updateDetails = this.admin + 'updateTimeTable/';



  /******************************************************* */
  createTimetablePostURL = this.admin + 'createTimeTable'

  /**************************************************
   * SUPER-ADMIN URLS
   *********************************************/

  categoryPostURL = this.admin + 'createCategory'
  boardPostURL = this.admin + 'createBoard'
  boardUpdateURL = this.admin + 'updateBoard/'
  gradePostURL = this.admin + 'createGrade'
  streamPostURL = this.admin + 'createStream'
  subjectPostURL = this.admin + 'createSubject'
  lessonPostURL = this.admin + 'createlesson'
  topicPostURL = this.admin + 'createTopic';
  topicEditURL = this.admin + 'updateTopic/';
  videoPostURL = this.admin + 'addVideoLinks'
  videoEditURL = this.admin + 'updateVideoLinks/'
  packagePostURL = this.admin + 'createPackage';
  packageEditURL = this.admin + 'updatePackage/'
  packageCategoryPostURL = this.admin + 'createPackageCategory';
  packageCategoryEditURL = this.admin + 'updatePackageCategory/';
  verifyMobileNumber = this.user + 'verifyPhoneNumber/';
  createaccountPostURL = this.admin + 'createAccount';
  createprofilePostURL = this.admin + 'createProfileByAdmin/';
  createTeacherPostURL = this.admin + 'createTeacher';

  gradeEditURL = this.admin + 'updateGrade/';
  streamEditURL = this.admin + 'updateStream/';
  subjectEditURL = this.admin + 'updateSubject/';
  lessonEditURL = this.admin + 'updateLesson/';

  McqPostURL = this.admin + 'createMCQs';
  getAllMCQs = this.admin + 'getAllMcq';
  mcqEditURL = this.admin + 'updateMCQs/';

  updateTimetableURL = this.admin + "updateTimetable/";

  /**************delete timetable****************************** */
  deleteBoard = this.admin + 'deleteBoard/';
  deleteGrade = this.admin + 'deleteGrade/';
  deleteStream = this.admin + 'deleteStream/';
  deleteSubject = this.admin + 'deleteSubject/';
  deleteLesson = this.admin + 'deleteLesson/';
  deleteTopic = this.admin + 'deleteTopic/';
  deletevideoLink = this.admin + 'deletevideoLink/';
  deleteTimetable = this.admin + 'deleteTimeTable/';
  deleteMcq = this.admin + 'deleteMcq/';
  deletePackage = this.admin + 'deletePackage/';
  deletePackageCategory = this.admin + 'deletePackageCategory/';

  /**
   * Create School Admin
   */
createSchoolAdmin = this.admin + 'SchoolAdmin'
getAllSchoolList = this.admin + 'getAllSchoolAdmin'
deleteSchoolById = this.admin + 'deleteSchoolAdmin/'
updateSchoolAdmin=this.admin+'updateSchoolAdmin/'
  constructor() { }
}

