import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  onNavigateFb() {
    window.open('https://www.facebook.com/mount.zionschool.9/', '_blank');
  }
  onNavigateYoutube() {
    window.open(
      'https://www.youtube.com/channel/UCeMJEYCKFz3hL6Sbu_M_wTA',
      '_blank'
    );
  }
  onNavigateinstagram() {
    window.open(' https://www.instagram.com/zioneduapp/', '_blank');
  }
  onNavigateGooglemybusiness() {
    window.open('https://g.page/r/CemHwJcYnMbiEAE', '_blank');
  }
}
