import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SuperAdminGuard } from 'src/app/core/guards/super-admin.guard';
import { AdminLoginComponent } from './pages/admin-login/admin-login.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { LandingRootComponent } from './pages/landing-root/landing-root.component';
import { LandingpageComponent } from './pages/landingpage/landingpage.component';
import { MarketingComponent } from './pages/marketing/marketing.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { TemsandconditionsComponent } from './pages/temsandconditions/temsandconditions.component';

const routes: Routes = [
  {
    path: '',
    component: LandingRootComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: LandingpageComponent,
        data: {
          animation: 'home'
        }
      },
      {
        path: 'contact',
        component: ContactusComponent,
        data: {
          animation: 'contact'
        }
      },
      {
        path: 'privacy',
        component: PrivacypolicyComponent,
      },
      {
        path: 'terms',
        component: TemsandconditionsComponent,
      },
      {
        path: 'promotions',
        component: MarketingComponent,
      },
      {
        path: 'pricing',
        component: PricingComponent,
      }
    ],
  },
  {
    path: 'admin',
    component: AdminLoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingRoutingModule {}
