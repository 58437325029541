import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-subject-card',
  templateUrl: './subject-card.component.html',
  styleUrls: ['./subject-card.component.scss']
})
export class SubjectCardComponent implements OnInit {

  @Input() backgroundColor: string = '#fffff';
  @Input() title: string = 'Subject';
  @Input() icon: string = '';
  @Input() content: string[] = ['Video classes', 'Practice sessions', 'Qualitative notes'];
  @Input() mini: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
