import { StorageProvider } from './../storage.provider';
export class Authorization {
  getToken() {
    return StorageProvider.getUserLoginToken();
  }

  getUserIdFromToken(token?: string): string {
    let jsonPayload = this.extractToken(token);
    if (!jsonPayload) return null;

    let userId = jsonPayload.id;

    return userId;
  }

  getUserTypeFromToken(token?: string) {
    let jsonPayload = this.extractToken(token);
    if (!jsonPayload) return null;

    let userType = jsonPayload.schoolSubscription;

    if(userType === true) return 'SCHOOL_STUDENT';
    if(userType === false) return 'PUBLIC_STUDENT';

    return null;
  }

  private extractToken(token: string) {
    if (!token) token = this.getToken();
    if (!token) return null;

    const payloadStart = token.indexOf('.') + 1;
    const payloadEnd = token.lastIndexOf('.');
    let payload = token.substring(payloadStart, payloadEnd);

    if (payload.length === 0) return null;

    payload = atob(payload);

    const jsonPayload: any = JSON.parse(payload);
    console.log('Extracted token', jsonPayload);
    return jsonPayload;
  }
}
