import { map } from 'rxjs/operators';
import { ApiResponse } from './../../models/api-response';
import { Injectable } from '@angular/core';
import { ApiProvider } from '../../http/api-provider';
import { HttpService } from '../../http/http.service';
import { BehaviorSubject } from 'rxjs';
import { SubjectSchool } from '../../models/subject.model';
import { Topic } from '../../models/packages.model';

@Injectable({
  providedIn: 'root',
})
export class SchoolMaterialsService {
  private apiUrl = '/admin';

  currentSelectedSubject$ = new BehaviorSubject<SubjectSchool>(null);
  currentSelectedTopic$ = new BehaviorSubject<Topic>(null);

  constructor(private http: HttpService, private apiProvider: ApiProvider) {
    this.apiUrl = apiProvider.adminApiUrl;
  }

  getAllBoards() {
    return this.http.get(`${this.apiUrl}/getBoards`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getGradesByBoardId(boardId: string) {
    return this.http.get(`${this.apiUrl}/getGrades/${boardId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getStreamsByGradeId(gradeId: string) {
    return this.http.get(`${this.apiUrl}/getStreams/${gradeId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getSubjectsByGradeIdAndStream(gradeId: string, stream: string) {
    return this.http
      .get(`${this.apiUrl}/getSubjects/${gradeId}`, {
        params: {
          streamName: stream,
        },
      })
      .pipe(
        map((response: ApiResponse) => {
          return response.data;
        })
      );
  }

  getLessonsBySubjectId(subjectId: string) {
    return this.http.get(`${this.apiUrl}/getLessons/${subjectId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getPackagesBySubjectId(subjectId: string, stream: string, gradeId: string, profileId: string) {
    return this.http
      .get(`${this.apiUrl}/getPackagesbySubject/${subjectId}`, {
        params: {
          streamName: stream,
          gradeId: gradeId,
          profileId: profileId
        },
      })
      .pipe(
        map((response: ApiResponse) => {
          return response.data;
        })
      );
  }

  getTopicsByLessonId(lessonId: string) {
    return this.http
    .get(`${this.apiUrl}/getTopics/${lessonId}`)
    .pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getVideosByTopic(topicId: string) {
    return this.http.get(`${this.apiUrl}/getVideos/${topicId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getMCQcByTopic(topicId: string) {
    return this.http.get(`${this.apiUrl}/getMCQs/${topicId}`).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getAllTuitionTeachersBySubject(subjectName: string, boardName:  string, gradeName: string, streamName: string) {
    return this.http
    .get(`${this.apiUrl}/getAllTuitionTeachersBySubject/${subjectName}`, {
      params: {
        board: boardName,
        grade: gradeName,
        stream: streamName
      },
    })
    .pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

}
