import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

function mightHaveBody(method: string): boolean {
  switch (method) {
    case 'DELETE':
    case 'GET':
    case 'HEAD':
    case 'OPTIONS':
    case 'JSONP':
      return false;
    default:
      return true;
  }
}

/* Wrapper Class for HttpClient */

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private _http: HttpClient) { }

  getMethod(method: string) {
    method = method.toUpperCase();
    switch (method) {
      case 'DELETE':
        return this.delete;
      case 'GET':
        return this.get;
      case 'HEAD':
        return this.head;
      case 'JSONP':
        return this.jsonp;
      case 'OPTIONS':
        return this.options;
      case 'POST':
        return this.post;
      case 'PUT':
        return this.put;
      case 'PATCH':
        return this.patch;
      default:
        throw new Error("Undefined HTTP Method");
    }
  }

  request(method: string, url: string, body?: any | {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: string;
    withCredentials?: boolean;
  } | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: string;
    withCredentials?: boolean;
  }): Observable<any> {
    method = method.toUpperCase();
    let methodFunction: Function = this.getMethod(method);
    if (mightHaveBody(method)) {
      return methodFunction.call(this,url,body,options);
    } else {
      return methodFunction.call(this,url,options);
    }
  }

  delete(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
  }): Observable<any> {
    return this._http.delete(url, options)
  }

  get(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
  }): Observable<any> {
    return this._http.get(url, options)
  }

  head(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
  }): Observable<any> {
    return this._http.get(url, options)
  }

  jsonp(url: string, callbackParam: string): Observable<any> {
    return this._http.jsonp(url, callbackParam)
  }

  options(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
  }): Observable<any> {
    return this._http.options(url, options)
  }

  post(url: string, body?: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
  }): Observable<any> {
    return this._http.post(url, body, options);
  }

  put(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
  }): Observable<any> {
    return this._http.put(url, body, options);
  }

  patch(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
  }): Observable<any> {
    return this._http.patch(url, body, options);
  }

}
