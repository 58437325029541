import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { RegistrationStudentCodeDialogComponent } from '../../../../../../../modules/landing/components/registration-student-code-dialog/registration-student-code-dialog.component';
import { LoginDialogComponent } from 'src/app/modules/landing/components/login-dialog/login.dialog.component';
import { User } from 'src/app/core/models/user.model';
import { UserAuthService } from 'src/app/core/services/user-auth/user-auth.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  isLoggedIn: boolean = false;

  currentUser: User = null; //add model

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private authService: UserAuthService,
    private dialog: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.getCurrentUser(true).subscribe((user) => this.currentUserChangeHandler(user));
    this.watchCurrentUser();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onLoginClick() {
    window.open("https://student.appsophia.com/", "_self");
    // const dialogRef = this.dialog.open(LoginDialogComponent, {
    //   maxWidth: 700,
    //   minWidth: 250,
    //   width: '500px',
    // });
  }

  onRegisterClick() {
    const dialogRef = this.dialog.open(RegistrationStudentCodeDialogComponent, {
      maxWidth: 700,
      minWidth: 250,
      width: 'auto',
    });
  }

  private watchCurrentUser() {
    this.subscriptions.add(this.userService.getCurrentUser$().subscribe((user) => this.currentUserChangeHandler(user)));
  }

  private currentUserChangeHandler(user: User) {
    console.log("Calling user in header", user);
    if (!user) {
      this.isLoggedIn = false;
      this.currentUser = null;
      return;
    }
    this.currentUser = user;
    this.isLoggedIn = true;
  }

  onLogOutClick() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

}
