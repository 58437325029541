import { Component, OnInit,Inject } from '@angular/core';
import { UserAuthService } from 'src/app/core/services/user-auth/user-auth.service';
import { ResetpasswordComponentComponent } from '../resetpassword-component/resetpassword-component.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-otpverification-component',
  templateUrl: './otpverification-component.component.html',
  styleUrls: ['./otpverification-component.component.scss']
})
export class OtpverificationComponentComponent implements OnInit {

  otpVerificationForm: FormGroup;
  hidePassword = true;
  constructor( private dialog: MatDialog,
    private auth: UserAuthService,
   public dialogRef: MatDialogRef<OtpverificationComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.otpVerificationForm= new FormGroup({
      otp: new FormControl(null, [Validators.required])

    });
  }

  ngOnInit(): void {
  }
async submit(){
  //  debugger
console.log(this.data.phoneNumber,"data")
this.auth.otpverificationsetting(this.otpVerificationForm.value,this.data.phoneNumber).subscribe(reponse=>{
  //console.log(reponse,"success");
           const dialogRef = this.dialog.open(ResetpasswordComponentComponent, {
      disableClose: true,
      maxWidth: 800,
      // minWidth: 250,
      width: '600px',
       height:'422px',
             panelClass: 'custom-modalbox-restpassword'
       
    });
})
  }
}
