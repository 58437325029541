import { User } from './models/user.model';

export class StorageProvider {

  private static UserLoginTokenKey = 'userLoginToken';
  private static UserTypeKey = 'userType';
  private static UserProfileKey = 'userProfile';




  static getUserLoginToken(fetchLocal?: boolean) {
    return getItem(this.UserLoginTokenKey, fetchLocal);
  }
  static setUserLoginToken(token: string, storeLocal?: boolean) {
    setItem(this.UserLoginTokenKey, token, storeLocal);
  }
  static removeUserLoginToken(storeLocal?: boolean) {
    removeItem(this.UserLoginTokenKey, storeLocal);
  }

  static getUserType(fetchLocal?: boolean) {
    return getItem(this.UserTypeKey, fetchLocal);
  }
  static setUserType(token: string, storeLocal?: boolean) {
    setItem(this.UserTypeKey, token, storeLocal);
  }
  static removeUserType(storeLocal?: boolean) {
    removeItem(this.UserTypeKey, storeLocal);
  }

}

function setItem(key: string, value: string, localStore?: boolean ) {
  sessionStorage.setItem(key, value);
  if (localStore) {
    localStorage.setItem(key,value);
  }
}

function getItem(key: string, localStore?: boolean ) {
  let item = sessionStorage.getItem(key);
  if (!item) {
    item = localStorage.getItem(key);
  }
  return item;
}


function removeItem(key: string, localStore?: boolean ) {
  sessionStorage.removeItem(key);
  if (localStore) {
    localStorage.removeItem(key);
  }
}
