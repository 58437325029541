import { TuitionDetailsComponent } from './components/tuition-details/tuition-details.component';
import { TuitionComponent } from './pages/tuition/tuition.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderFooterModule } from './modules/header-footer/components/header-footer/header-footer.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { SubjectCardComponent } from './components/subject-card/subject-card.component';

@NgModule({
  declarations: [
    TuitionComponent,
    TuitionDetailsComponent,
    SubjectCardComponent
  ],

  imports: [
    CommonModule,
    HeaderFooterModule,
    FlexLayoutModule,
    MaterialModule
  ],

  exports: [
    TuitionComponent,
    TuitionDetailsComponent,
    SubjectCardComponent,
    HeaderFooterModule,
    FlexLayoutModule,
    MaterialModule
  ]

})
export class SharedModule { }
