import { SubjectSchool } from './../../../core/models/subject.model';
import { SchoolMaterialsService } from './../../../core/services/school-materials/school-materials.service';
import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { TuitionSubject, TuitionTeacher } from 'src/app/core/models/tuition.model';

@Component({
  selector: 'app-tuition-details',
  templateUrl: './tuition-details.component.html',
  styleUrls: ['./tuition-details.component.scss'],
})
export class TuitionDetailsComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  tuitionTeachers: TuitionTeacher[] = [];

  constructor(
    private schoolMaterialService: SchoolMaterialsService,
    private dialogRef: MatDialogRef<TuitionDetailsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      subject: SubjectSchool,
      subjectName: string;
      boardName: string;
      gradeName: string;
      streamName: string;
    }
  ) {}

  ngOnInit() {
    this.getAllTuitionTeachers();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getAllTuitionTeachers() {
    this.subscriptions.add(
      this.schoolMaterialService
        .getAllTuitionTeachersBySubject(
          this.data.subjectName,
          this.data.boardName,
          this.data.gradeName,
          this.data.streamName
        )
        .subscribe((teachers) => {
          this.tuitionTeachers = teachers;
          console.log(this.tuitionTeachers);
        })
    );
  }
}
