import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RegistrationOtpVerificationDialogComponent } from '../registration-otp-verification-dialog/registration-otp-verification-dialog.component';
import { UserRegisterMethods } from 'src/app/core/models/user.model';
import { UserAuthService } from 'src/app/core/services/user-auth/user-auth.service';
import { AlertService } from 'src/app/core/services/alert/alert.service';
import { ApiService } from 'src/app/core/services/ApiService/apiService';

@Component({
  selector: 'app-mobileno-dialog',
  templateUrl: './registration-mobileno-dialog.component.html',
  styleUrls: ['./registration-mobileno-dialog.component.scss'],
})
export class RegistrationMobilenoDialogComponent implements OnInit, OnDestroy {
  userMobile: string;

  private subscriptions = new Subscription();

  constructor(
    private authService: UserAuthService,
    private alertService: AlertService,
    private apiService: ApiService,
    private dialogRef: MatDialogRef<RegistrationMobilenoDialogComponent>,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

   onClickNext() {
    this.subscriptions.add(
      this.authService.verifyPhoneNumber(this.userMobile).subscribe(
        () => this.openOTPVerificationDialog(),
        (err) => this.alertService.error(err.error?.message)
      )
    );
  }

  openOTPVerificationDialog() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(
      RegistrationOtpVerificationDialogComponent,
      {
        maxWidth: 700,
        minWidth: 250,
        width: '500px',
        data: {
          mobileNo: this.userMobile,
          directedFrom: UserRegisterMethods.MOBILE_NO,
        },
      }
    );
  }
}
