import { StorageProvider } from './../../storage.provider';
import { UserService } from './../user/user.service';
import { ApiResponse } from './../../models/api-response';
import { Injectable } from '@angular/core';
import { map, mergeMap, takeLast, catchError, take } from 'rxjs/operators';
import { ApiProvider } from 'src/app/core/http/api-provider';
import { HttpService } from 'src/app/core/http/http.service';
import { of, concat, throwError, BehaviorSubject } from 'rxjs';
import { Authorization } from '../../models/auth.provider';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class UserAuthService {
  private apiUrl = '/user';

  constructor(
    private http: HttpClient,
    private apiProvider: ApiProvider,
    private userService: UserService
  ) {
    this.apiUrl = apiProvider.userApiUrl;
  }

  authenticate(
    phoneNo: string,
    password: string,
    userType: string,
    keepMeLoggedIn: boolean
  ) {
    return this.http
      .post(this.apiUrl + '/authenticate', {
        phoneNumber: phoneNo,
        password: password,
        userType: userType,
      })
      .pipe(
        mergeMap((response: ApiResponse) => {
          if (!response.success || !response.data) return throwError(response);
          const token = response.data.token;
          StorageProvider.setUserLoginToken(token); // Recieves Token from Back-end
          let getCurrentUser$ = this.userService.getCurrentUser(true);
          return concat(getCurrentUser$, of(response)).pipe(takeLast(1));
        })
      );
  }

  isLoggedIn() {
    if (this.getToken()) {
      return true;
    }
    return false;
  }

  getToken() {
    let userLoginToken = StorageProvider.getUserLoginToken(true);
    return userLoginToken;
  }

  authenticateHandler(response: ApiResponse) {
    if (!response.data || !response.success) return throwError(response);

    const token = response.data.token;

    StorageProvider.setUserLoginToken(token);

    this.userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((user) => {
        console.log('User updated!', user);
      });
  }

  verifyStudentCode(studentcode: string) {
    return this.http
      .get(`${this.apiUrl}/verifyUserByStudentCode/${studentcode}`)
      .pipe(
        (response) => {
          return response;
        } //**save user detail
      );
  }

  verifyPhoneNumber(phoneNumber: string) {
    return this.http
      .get(`${this.apiUrl}/verifyPhoneNumber/${phoneNumber}`)
      .pipe((response) => {
        return response;
      });
  }

  verifyMobileOtp(otp: string, mobileNo: string) {
    return this.http
      .get(`${this.apiUrl}/verifyOTP/${otp}?phoneNumber=${mobileNo}`)
      .pipe(
        map((response: ApiResponse) => {
          this.authenticateHandler(response);
          return response;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  verifyStudentCodeOtp(otp: string, mobileNo: string) {
    return this.http
      .get(`${this.apiUrl}/studentVerifyOTP/${otp}?phoneNumber=${mobileNo}`)
      .pipe(
        map((response: ApiResponse) => {
          this.authenticateHandler(response);
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  forgotsetting(obj) {
    //debugger
    console.log(obj, 'obj');
    return this.http
      .get(`${this.apiUrl}/forgotPasswordPhone/${obj.phoneNumber}`)
      .pipe(
        map((response: ApiResponse) => {
          this.authenticateHandler(response);
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
  otpverificationsetting(obj, phoneNo) {
    //debugger

    return this.http
      .get(`${this.apiUrl}/forgotPasswordOTP/${obj.otp}?phoneNumber=${phoneNo}`)
      .pipe(
        map((response: ApiResponse) => {
          this.authenticateHandler(response);
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  async resetpassword(obj) {
    console.log(obj, 'obj');
    let p = {
      newPassword: obj.password,
      phoneNumber: obj.phoneNumber,
    };
    const response = await this.http
      .put(`${this.apiUrl}/forgotPassword`, p, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }

  logout(forgetMe: boolean = true) {
    this.userService.setCurrentUser(null);
    StorageProvider.removeUserLoginToken(true);
    StorageProvider.removeUserType(true);
    if (forgetMe) {
    }
  }
}
