import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppConfig } from './app/config/app-config';
import { ApiProvider } from './app/core/http/api-provider';
import { environment } from './environments/environment';

AppConfig.initialize(environment);
ApiProvider.initialize();

if (AppConfig.isProduction()) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
