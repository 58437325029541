import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/core/services/user-auth/user-auth.service';
import { MatDialog } from '@angular/material/dialog';
import {OtpverificationComponentComponent } from '../otpverification-component/otpverification-component.component';
@Component({
  selector: 'app-forgotpassword-component',
  templateUrl: './forgotpassword-component.component.html',
  styleUrls: ['./forgotpassword-component.component.scss']
})
export class ForgotpasswordComponentComponent implements OnInit {
 forgotpasswordForm: FormGroup;hidePassword = true;
  constructor( private auth: UserAuthService, private dialog: MatDialog) {
   
  
     this.forgotpasswordForm = new FormGroup({
      phoneNumber: new FormControl(null, [Validators.required])

    });
  }

  ngOnInit(): void {
  }
async submit(){
  //  debugger;

    let forgotpswd=this.forgotpasswordForm.value;
   console.log(forgotpswd.phoneNumber,"emaill");
    this.auth.forgotsetting(forgotpswd).subscribe(response=>{
  //console.log(response,"success");
          const dialogRef = this.dialog.open(OtpverificationComponentComponent, {
      disableClose: true,
      maxWidth: 800,
      // minWidth: 250,
      width: '500px',
      height:'422px',
            panelClass: 'custom-modalbox-password',
            data: {
         phoneNumber:forgotpswd.phoneNumber
       }
    });
})
  }
}
