import { Component, HostBinding, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RegistrationStudentCodeDialogComponent } from '../../../../modules/landing/components/registration-student-code-dialog/registration-student-code-dialog.component';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss'],
  animations: []
})
export class LandingpageComponent implements OnInit {
  constructor(private router: Router, private dialog: MatDialog) {}

  whySophia = [
    'Best Teachers',
    'Adaptive Teaching',
    'Convenient & Safe',
    'Live & Interactive'
  ];

  learn = [
    {
      title: 'Live Classes',
      sub: 'Learn from best teachers, ask questions and take live sessions'
    },
    {
      title: 'Timetable based Classes',
      sub: 'High-quality learning at your own pace in your own space'
    },
    {
      title: 'Concept And Stories',
      sub: 'Quick revision with definitions, diagrams and examples'
    },
    {
      title: 'Adaptive Practice',
      sub: 'Master your concepts with questions that adapt to your learning speed'
    },
    {
      title: 'Qualitative notes',
      sub: 'Providing qualitative notes to develop the critical thinking skills.'
    },
    {
      title: 'Flexible learning schedule',
      sub: 'Access course materials from anywhere and anytime using mobile app'
    },
    {
      title: 'Review lectures instantly',
      sub: 'Students can review words from teachers instantly ,either by rewinding the video or by reading the notes that accompanies the lectures'
    },
    {
      title: 'Engaging material',
      sub: 'Provide with active learning, helping to increase their retention of course material.'
    },
    {
      title: 'Multiple ways to communicate',
      sub: 'Email, chat and telephone conversations,more oppurtunities to get feedback or have a great Q&A session with your teachers'
    },
    {
      title: 'Video Classes',
      sub: 'High-quality learning at your own pace in your own space'
    },
  ];

  ngOnInit(): void {}
  onRegisterClick() {
    const dialogRef = this.dialog.open(RegistrationStudentCodeDialogComponent, {
      maxWidth: 700,
      minWidth: 250,
      width: 'auto',
    });
  }
}
