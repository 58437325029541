import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConfig {

  // Debug / Production
  private static debugging = false;
  private static production = false;

  // API Config
  private static apiProtocol = null ;
  private static apiDomain = null;
  private static apiPort = null;
  private static apiPathPrefix = "api";
  private static apiBaseUrl = null;

  static initialize(environment) {
    this.debugging = environment.debugging;
    this.production = environment.production;
    this.configureBaseApiUrl(environment);
  }

  static isDebugging() {
    return this.debugging;
  }

  static isProduction() {
    return this.production;
  }

  static getApiBaseUrl() {
    return this.apiBaseUrl;
  }

  private static configureBaseApiUrl(environment: any) {
    this.apiProtocol = environment.apiProtocol;
    this.apiDomain = environment.apiDomain;
    this.apiPort = environment.apiPort;
    this.apiPathPrefix = environment.apiPathPrefix;
    if (this.apiProtocol === null) this.apiProtocol = window.location.protocol;
    if (this.apiDomain === null) this.apiDomain = window.location.hostname;
    if (this.apiPort === null) this.apiPort = window.location.port;
    if (this.apiPathPrefix === null) this.apiPathPrefix = 'api';
    this.apiBaseUrl = `${this.apiProtocol}//${this.apiDomain}/${this.apiPathPrefix}`;
    return this.apiBaseUrl;
  }

  constructor() { }
}
