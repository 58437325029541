import { BoardUpdate } from './../../models/board.model';
import { EduwebendpointService } from './../Endpoint/eduwebendpoint.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
// import { EduwebendpointService } from 'src/app/services/Endpoint/eduwebendpoint.service';
import { Authenticationservice } from '../Auth/authenticationService';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { PackageUpdate } from '../../models/package.model';
import { TopicUpdate } from '../../models/topic.model';
import { GradeUpdate } from '../../models/grade';
import { StreamUpdate } from '../../models/stream.mosels';
import * as stream from 'stream';
import { LessonUpdate } from '../../models/lesson.model';
import { SubjectUpdate } from '../../models/subject.model';
import { McqUpdate } from '../../models/mcqcreation.model';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private headers = new HttpHeaders({ 'Content-type': 'application/json' });
  options = { headers: this.headers };
  VerifyToken = sessionStorage.getItem('Token');
  headers_object = new HttpHeaders().set(
    'Authorization',
    'Bearer' + this.VerifyToken
  );
  public _subjectForPopup = new Subject();
  public _subjectForTableData = new Subject();

  public createaccountnfoDs = new BehaviorSubject<any>(null);
  public isCloseDialog = new Subject<any>();
  public createaccountnPhn = new BehaviorSubject<any>(null);
  public PROFILEInfoDs = new BehaviorSubject<any>(null);
  public TimetableInfoDs = new BehaviorSubject<any>(null);
  updateSchooldata: any = {};
  updatecondition: boolean;
  setSaveSchool: any = {};
  constructor(
    private endpoint: EduwebendpointService,
    private http: HttpClient,
    private auth: Authenticationservice
  ) {}
  public selectedrowInfoDs = new BehaviorSubject<any>(null);
  verifyphonenumber(mobile) {
    console.log('mobile', mobile);
    return this.http.get(
      this.endpoint.verifyPhioneNumber + mobile,
      this.options
    );
  }

  verifyOtp(otp, mobile) {
    //done
    return this.http
      .get(
        this.endpoint.verifyOtp + otp + '?phoneNumber=' + mobile,
        this.options
      )
      .pipe(
        map((response) => this.auth.loginHandlerOnOtpVerification(response)),
        catchError(this.auth.otpErrorHandler)
      );
  }
  verifyStudentCodeOtp(otp, mobile) {
    //done
    return this.http
      .get(
        this.endpoint.verifyOtpforStudentcode + otp + '?phoneNumber=' + mobile,
        this.options
      )
      .pipe(
        map((response) => this.auth.loginHandlerOnOtpVerification(response)),
        catchError(this.auth.otpErrorHandler)
      );
  }

  CreateProfile(formdata, id) {
    console.log('form', formdata);
    // console.log("VERIFY LOG AUTH",this.VerifyToken)
    this.headers = Authenticationservice.authorizeHeader(this.headers);
    // this.headers_object=Authenticationservice.authorizeHeader(this.headers_object)
    console.log('headers', this.headers);
    return this.http.post<HttpResponse<any>>(
      this.endpoint.createProfiles + id,
      formdata,
      { headers: this.headers }
    );
  }
  getMyProfileDetails(id) {
    this.headers = Authenticationservice.authorizeHeader(this.headers);
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getMyProfileById + id,
      { headers: this.headers }
    );
  }
  getPackageBYgradeIdandStream(gradeid, streamName) {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getPackageBYgradeIdandStream +
        gradeid +
        '?streamName=' +
        streamName,
      this.options
    );
  }
  getAllPackages() {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getAllPackages,
      this.options
    );
  }
  getAllBoards() {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getAllBoards,
      this.options
    );
  }

  getSchoolList() {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getAllSchoolList,
      this.options
    );
  }

  getGradesByBoardId(id) {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getGradesByBoardId + id,
      this.options
    );
  }

  getStreams(id) {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getStreamsByGradeId + id,
      this.options
    );
    console.log(Response);
  }

  getSubject(gradeid, streamName) {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getSubjects + gradeid + '?streamName=' + streamName,
      this.options
    );
  }

  getPackagesBySubjectStreamandGradeId(subjectid, streamName, gradeId) {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getPackagesbySubject +
        subjectid +
        '?streamName=' +
        streamName +
        '&gradeId=' +
        gradeId,
      this.options
    );
  }

  getAllPackageCategories() {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getAllPackageCategories,
      this.options
    );
  }
  // getLessions(gradeid){
  //   // let gradeId="5f9acac2bb305a2083e7552e"

  //   return this.http.get<HttpResponse<any>>(this.endpoint.getLessionsBySublectId+gradeid,this.options)
  // }
  getTopics(gradeid) {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getTopics + gradeid,
      this.options
    );
  }
  getVideos(gradeid) {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getVideos + gradeid,
      this.options
    );
  }
  getVideostopicid(topicid) {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getVideos + topicid,
      this.options
    );
  }
  getMCQdetails(topicid) {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getMCQ + topicid,
      this.options
    );
  }
  getTimeTable(boardId: string, gradeId: string, streamId: string) {
    return this.http.get<any>(
      // 'https://www.appsophia.com/edu/api/admin/getTimetable?boardId=5f9bd948c7191f026c776146&&gradeId=5f9bda88c7191f026c77614a&&streamId=5f9bf053c7191f026c77615d'
      `https://www.appsophia.com/edu/api/admin/getTimetable?boardId=${boardId}&&gradeId=${gradeId}&&streamId=${streamId}`
    );
  }
  getTimetable(timetableid) {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getTimetable + timetableid,
      this.options
    );
  }
  //****=================>>>>>Admin-createaccount<<<<<<<<================****//

  ////////////===============>>>>>>>>>>SUPER-ADMIN<<<<<<<<=================/////////////////

  ///////=======>>>>>>>>>>MASTER-MANAGEMENT<<<<<<======//////

  //==>>CATEGORY<<==//

  //=>POST<=//
  async categorypost(create): Promise<HttpResponse<any>> {
    // debugger
    console.log(create);
    let c = { categoryName: create.categoryName };
    const response = await this.http
      .post<HttpResponse<any>>(this.endpoint.categoryPostURL, c, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  async pacakageCategorypost(category): Promise<HttpResponse<any>> {

    const response = await this.http
      .post<HttpResponse<any>>(this.endpoint.packageCategoryPostURL, category, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  async boardpost(create, catid): Promise<HttpResponse<any>> {
    // debugger
    console.log(create);
    let b = {
      board: create.board,
      boardName: create.boardName,
      categoryId: catid.selectedcategoryid,
    };
    const response = await this.http
      .post<HttpResponse<any>>(this.endpoint.boardPostURL, b, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }

  async boardedit(
    boards: BoardUpdate,
    baordId: string
  ): Promise<HttpResponse<any>> {
    console.log('baodrdId', baordId);
    console.log('baodrs', boards);
    let header = new HttpHeaders();
    header = Authenticationservice.authorizeHeader(header);
    //   let p={
    //  board: boards.board,
    //  boardName: boards.boardName,
    //  categoryId: boards.categoryId,
    //  };
    // const formdata = new FormData();
    // formdata.append('board', boards.board);
    // formdata.append('boardName', boards.boardName);
    //  formdata.append('categoryId', boards.categoryId);
    const response = await this.http
      .put<HttpResponse<any>>(this.endpoint.boardUpdateURL + baordId, boards, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  async gradepost(create, catid, boardid): Promise<HttpResponse<any>> {
    // debugger
    console.log(create);
    let g = {
      grade: create.grade,
      gradeName: create.gradeName,
      //board:create.boardName,
      categoryId: catid,
      boardId: boardid,
    };
    const response = await this.http
      .post<HttpResponse<any>>(this.endpoint.gradePostURL, g, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  async streampost(stream, catid, grad, boardid): Promise<HttpResponse<any>> {
    //debugger
    console.log(stream);
    let p = {
      streamName: stream.streamName,
      categoryId: catid,
      gradeId: grad,
      boardId: boardid,
    };
    const response = await this.http
      .post<HttpResponse<any>>(this.endpoint.streamPostURL, p, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  //   async subjectpost(stream,catid,grad,boardid,streamid): Promise<HttpResponse<any>> {
  //     //debugger
  //     console.log(stream);
  //      let p={
  //       streamName:stream.streamName,
  //       subjectName:stream.subjectName,
  //       description:stream.description,
  //       categoryId:catid,
  //       gradeId:grad,
  //       boardId:boardid,
  //       streamId:streamid,

  //  }
  //     const response = await this.http.post<HttpResponse<any>>(this.endpoint.subjectPostURL,p, { observe: 'response' }).toPromise();
  //     return response;
  //   }
  async subjectpost(
    stream,
    subject_image,
    catid,
    grad,
    boardid,
    streamid,
    selectedstream,
    color
  ): Promise<HttpResponse<any>> {
    //  console.log(stream); // debugger
    console.log(stream);
    let header = new HttpHeaders();
    header = Authenticationservice.authorizeHeader(header);

    const formdata = new FormData();
    const options = { headers: header };
    formdata.append('subjectName', stream.subjectName),
      formdata.append('description', stream.description),
      formdata.append('subjectImage', subject_image),
      formdata.append('categoryId', catid),
      formdata.append('gradeId', grad),
      formdata.append('boardId', boardid),
      formdata.append('streamId', streamid),
      formdata.append('streamName', selectedstream);
    formdata.append('color', color);
    const response = await this.http
      .post<HttpResponse<any>>(this.endpoint.subjectPostURL, formdata, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }

  async lessonpost(
    stream,
    catid,
    grad,
    boardid,
    streamid,
    subid
  ): Promise<HttpResponse<any>> {
    //debugger
    let p = {
      lessonName: stream.lessonName,
      lessonNumber: stream.lessonNumber,
      description: stream.description,
      categoryId: catid,
      gradeId: grad,
      boardId: boardid,
      streamId: streamid,
      subjectId: subid,
    };
    const response = await this.http
      .post<HttpResponse<any>>(this.endpoint.lessonPostURL, p, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  async topicpost(
    stream,
    catid,
    grad,
    boardid,
    streamid,
    subid,
    lessonid,
    topic_image
  ): Promise<HttpResponse<any>> {
    //     // console.log(stream); //     //debugger
    let header = new HttpHeaders();
    header = Authenticationservice.authorizeHeader(header);
    const formdata = new FormData();
    const options = { headers: header };
    formdata.append('topicName', stream.topicName),
      formdata.append('description', stream.description),
      formdata.append('categoryId', catid),
      formdata.append('gradeId', grad),
      formdata.append('boardId', boardid),
      formdata.append('streamId', streamid),
      formdata.append('subjectId', subid),
      formdata.append('lessonId', lessonid),
      formdata.append('topicImage', topic_image);

    const response = await this.http
      .post<HttpResponse<any>>(this.endpoint.topicPostURL, formdata, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }

  async topicStatusEdit(
    topic: TopicUpdate,
    topicId: string
  ): Promise<HttpResponse<any>> {
    console.log('topic', topic);
    let header = new HttpHeaders();
    header = Authenticationservice.authorizeHeader(header);

    const response = await this.http
      .put<HttpResponse<any>>(this.endpoint.topicEditURL + topicId, topic, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }

  async topicedit(
    topic: TopicUpdate,
    topicId: string
  ): Promise<HttpResponse<any>> {
    console.log('topic', topic);
    let header = new HttpHeaders();
    header = Authenticationservice.authorizeHeader(header);

    const formdata = new FormData();

    formdata.append('topicName', topic.topicName);
    formdata.append('description', topic.description);
    formdata.append('categoryId', topic.categoryId);
    formdata.append('gradeId', topic.gradeId);
    formdata.append('boardId', topic.boardId);
    formdata.append('streamId', topic.streamId),
    formdata.append('subjectId', topic.subjectId);
    formdata.append('lessonId', topic.lessonId);
    if (topic.file) {
      formdata.append('topicImage', topic.file);
    }
    const response = await this.http
      .put<HttpResponse<any>>(this.endpoint.topicEditURL + topicId, formdata, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  //   async  topicpost(stream,catid,grad,boardid,streamid,subid,lessonid): Promise<HttpResponse<any>> {
  //     //debugger
  //     console.log(stream);
  //     let p={
  //       topicName:stream.topicName,
  //       // subjectName:stream.subjectName,
  //       // lessonName:stream.lessonName,
  //       // lessonNumber:stream.lessonNumber,
  //       description:stream.description,
  //       categoryId:catid,
  //       gradeId:grad,
  //       boardId:boardid,
  //       streamId:streamid,
  //       subjectId:subid,
  //       lessonId:lessonid
  //  }
  //     const response = await this.http.post<HttpResponse<any>>(this.endpoint.topicPostURL,p, { observe: 'response' }).toPromise();
  //     return response;
  //   }
  // async videopost(createVideofrmgp,
  //   selectedcategoryid,
  //   selectedGradeid,
  //   selectedBoardid,
  //   selectedstreamid,
  //   selectedsubjectid,
  //   selectedlessonid,
  //   selectedtopicid,
  //   ): Promise<HttpResponse<any>> {

  //     console.log(createVideofrmgp);
  //     {
  //     let header = new HttpHeaders();
  //     header =Authenticationservice.authorizeHeader(header);
  //     const formdata = new FormData();
  //     const options = { headers: header };
  // formdata.append("videoName",createVideofrmgp.videoName),
  // formdata.append("videoType",createVideofrmgp.videoType),
  // formdata.append("videoLink",createVideofrmgp.videoLink),
  // formdata.append("description",createVideofrmgp.description),

  // formdata.append("categoryId",selectedcategoryid),
  // formdata.append("gradeId",selectedGradeid),
  // formdata.append("boardId",selectedBoardid),
  // formdata.append("streamId",selectedstreamid),
  // formdata.append("subjectId",selectedsubjectid),
  // formdata.append("lessonId",selectedlessonid),
  // formdata.append("topicId",selectedtopicid)
  //     }
  //     const response = await this.http.post<HttpResponse<any>>(this.endpoint.videoPostURL,FormData, { observe: 'response' }).toPromise();
  //     return response;
  //   }
  async videopost(
    createVideofrmgp,
    selectedcategoryid,
    selectedGradeid,
    selectedBoardid,
    selectedstreamid,
    selectedsubjectid,
    selectedlessonid,
    selectedtopicid
  ): Promise<HttpResponse<any>> {
    let p = {
      videoName: createVideofrmgp.videoName,
      videoType: createVideofrmgp.videoType,
      videoLink: createVideofrmgp.videoLink,
      description: createVideofrmgp.description,
      categoryId: selectedcategoryid,
      gradeId: selectedGradeid,
      boardId: selectedBoardid,
      streamId: selectedstreamid,
      subjectId: selectedsubjectid,
      lessonId: selectedlessonid,
      topicId: selectedtopicid,
    };
    const response = await this.http
      .post<HttpResponse<any>>(this.endpoint.videoPostURL, p, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  async videoedit(videoform, id): Promise<HttpResponse<any>> {
    let p = {
      videoName: videoform.videoName,
      videoType: videoform.videoType,
      videoLink: videoform.videoLink,
      description: videoform.description,
      categoryId: videoform.categoryName,
      gradeId: videoform.gradeName,
      boardId: videoform.boardName,
      streamId: videoform.streamName,
      subjectId: videoform.subjectName,
      lessonId: videoform.lessonName,
      topicId: videoform.topicName,
    };
    const response = await this.http
      .put<HttpResponse<any>>(this.endpoint.videoEditURL + id, p, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }

  async pakagepost(packageUpdate: PackageUpdate): Promise<HttpResponse<any>> {
    let p = packageUpdate;
    const response = await this.http
      .post<HttpResponse<any>>(this.endpoint.packagePostURL, p, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }

  updatePackage(packageUpdate: PackageUpdate, packageId) {
    let update: any = packageUpdate;
    let formData = new FormData();
    for (let key in update) {
      formData.append(key, update[key]);
    }
    return this.http.put<HttpResponse<any>>(
      this.endpoint.packageEditURL + packageId,
      packageUpdate,
      this.options
    );
  }

  updatePacakageCategory(update, id) {
    return this.http.put<HttpResponse<any>>(
      this.endpoint.packageCategoryEditURL + id,
      update,
      this.options
    );
  }

  getStreamByGradesId(id) {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getStreamByGradeId + id,
      this.options
    );
  }

  updateEditDetails(params, objectpost) {
    return this.http.put<HttpResponse<any>>(
      this.endpoint.updateDetails + params,
      objectpost,
      this.options
    );
  }

  createAccount(formdata, id) {
    return this.http.post<HttpResponse<any>>(
      this.endpoint.createProfiles + id,
      formdata,
      this.options
    );
  }

  getLessions(gradeid) {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getLessionsBySublectId + gradeid,
      this.options
    );
  }

  //////////***************=================>>>>>Admin<<<<<<<<================***************////////////////////////////
  //****=================>>>>>Admin-createaccount<<<<<<<<================****//
  async createaccountpost(create): Promise<HttpResponse<any>> {
    // debugger
    console.log(create);
    let c = { number: create.phoneNumber };
    const response = await this.http
      .post<HttpResponse<any>>(this.endpoint.createaccountPostURL, c, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  async createprofilepost(id, profile): Promise<HttpResponse<any>> {
    //debugger
    console.log(profile);

    const response = await this.http
      .post<HttpResponse<any>>(
        this.endpoint.createprofilePostURL + id,
        profile,
        { observe: 'response' }
      )
      .toPromise();
    return response;
  }
  async createteacherpost(profile): Promise<HttpResponse<any>> {
    //debugger
    console.log(profile);

    console.log(profile.files, 'teacher');
    //let id=profile._id
    //console.log(,'iddddddd')

    let header = new HttpHeaders();
    header = Authenticationservice.authorizeHeader(header);

    const formdata = new FormData();
    formdata.append('firstName', profile.firstName);
    formdata.append('middleName', profile.middleName);
    formdata.append('lastName', profile.lastName);
    formdata.append('dateOfBirth', profile.dateOfBirth);
    formdata.append('gender', profile.gender);
    formdata.append('bloodGroup', profile.bloodGroup);
    formdata.append('board', profile.boardId);
    formdata.append('grade', profile.gradeId);
    formdata.append('stream', profile.streamId);
    formdata.append('maritalStatus', profile.maritalStatus);
    formdata.append('birthPlace', profile.birthPlace);
    formdata.append('aadharNo', profile.aadharNo);
    formdata.append('nationality', profile.nationality);
    formdata.append('motherTongue', profile.motherTongue);
    formdata.append('position', profile.position);
    formdata.append('yearOfExperience', profile.yearOfExperience);
    formdata.append('subject', profile.subject);

    if (profile.file) {
      formdata.append('teacherImage', profile.file);
    }

    const response = await this.http
      .post<HttpResponse<any>>(this.endpoint.createTeacherPostURL, formdata, {
        observe: 'response',
      })
      .toPromise();
    return response;
    // const response = await this.http
    //   .post<HttpResponse<any>>(
    //     this.endpoint.createTeacherPostURL + id,profile

    //     { observe: 'response' }
    //   )
    //   .toPromise();
    // return response;
  }

  getAllGrades() {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getAllGrades,
      this.options
    );
  }
  getAllStreams() {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getAllStreams,
      this.options
    );
  }
  getAllSubjects() {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getAllSubjects,
      this.options
    );
  }

  getAlltopics() {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getAlltopics,
      this.options
    );
  }
  getAllVideos() {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getAllVideos,
      this.options
    );
  }
  getAllLessons() {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getAllLessons,
      this.options
    );
  }
  getAllMCQs() {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getAllMCQs,
      this.options
    );
  }
  async createTimetable(timetabledata): Promise<HttpResponse<any>> {
    console.log(timetabledata, 'timetabledata');
    const response = await this.http
      .post<HttpResponse<any>>(
        this.endpoint.createTimetablePostURL,
        timetabledata,
        {
          observe: 'response',
        }
      )
      .toPromise();
    return response;
  }

  async createSchoolAdmin(data): Promise<HttpResponse<any>> {
    const response = await this.http
      .post<HttpResponse<any>>(this.endpoint.createSchoolAdmin, data, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }

  async updateSchoolAdmin(data): Promise<HttpResponse<any>> {
    let updatavlues = {
      schoolName: data.schoolName,
      schoolAddress: data.schoolAddress,
      phoneNumber: data.phoneNumber,
      email: data.email,
    };
    const response = await this.http
      .put<HttpResponse<any>>(
        this.endpoint.updateSchoolAdmin + data.id,
        updatavlues,
        {
          observe: 'response',
        }
      )
      .toPromise();
    return response;
  }
  /********************************edit */
  async gradeEdit(
    gradeValue: GradeUpdate,
    gradeId: string
  ): Promise<HttpResponse<any>> {
    let header = new HttpHeaders();
    header = Authenticationservice.authorizeHeader(header);
    //       let p={
    //  categoryId: gradeValue.categoryId,
    //  boardId: gradeValue.boardId,
    //  grade: gradeValue.grade,
    //  gradeName: gradeValue.gradeName,

    //  };
    // const formdata = new FormData();
    // formdata.append('categoryId', gradeValue.categoryId);
    // formdata.append('boardId', gradeValue.boardId);
    // formdata.append('grade', gradeValue.grade);
    // formdata.append('gradeName', gradeValue.gradeName);
    const response = await this.http
      .put<HttpResponse<any>>(
        this.endpoint.gradeEditURL + gradeId,
        gradeValue,
        {
          observe: 'response',
        }
      )
      .toPromise();
    return response;
  }
  async streamEdit(
    stream: StreamUpdate,
    streamId: string
  ): Promise<HttpResponse<any>> {
    let header = new HttpHeaders();
    header = Authenticationservice.authorizeHeader(header);

    // const formdata = new FormData();
    // formdata.append('categoryId', stream.categoryId);
    // formdata.append('boardId', stream.boardId);
    // formdata.append('gradeId', stream.gradeId);
    //  formdata.append('streamName', stream.streamName);
    const response = await this.http
      .put<HttpResponse<any>>(this.endpoint.streamEditURL + streamId, stream, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  async lessonEdit(
    lesson: LessonUpdate,
    lessonId: string
  ): Promise<HttpResponse<any>> {
    let header = new HttpHeaders();
    header = Authenticationservice.authorizeHeader(header);

    const response = await this.http
      .put<HttpResponse<any>>(this.endpoint.lessonEditURL + lessonId, lesson, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }

  async mcqEdit(
    mcqUpdate: McqUpdate,
    mcqId: string
  ): Promise<HttpResponse<any>> {
    let header = new HttpHeaders();
    header = Authenticationservice.authorizeHeader(header);

    const response = await this.http
      .put<HttpResponse<any>>(this.endpoint.mcqEditURL + mcqId, mcqUpdate, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }

  async subjectStatusEdit(
    subject: any,
    subjectId: string
  ): Promise<HttpResponse<any>> {
    let header = new HttpHeaders();
    header = Authenticationservice.authorizeHeader(header);

    const response = await this.http
      .put<HttpResponse<any>>(
        this.endpoint.subjectEditURL + subjectId,
        subject,
        {
          observe: 'response',
        }
      )
      .toPromise();
    return response;
  }

  async subjectEdit(
    subject: SubjectUpdate,
    subjectId: string
  ): Promise<HttpResponse<any>> {
    console.log(subject, 'subject');
    let header = new HttpHeaders();
    header = Authenticationservice.authorizeHeader(header);

    const formdata = new FormData();
    formdata.append('categoryId', subject.categoryId);
    formdata.append('boardId', subject.boardId);
    formdata.append('gradeId', subject.gradeId);
    formdata.append('streamId', subject.streamId);
    formdata.append('streamName', subject.streamName);
    formdata.append('subjectName', subject.subjectName);
    formdata.append('description', subject.description);
    formdata.append('color', subject.color);
    if (subject.file) {
      formdata.append('subjectImage', subject.file);
    }
    const response = await this.http
      .put<HttpResponse<any>>(
        this.endpoint.subjectEditURL + subjectId,
        formdata,
        {
          observe: 'response',
        }
      )
      .toPromise();
    return response;
  }
  async mcqPost(mcqUpdate: McqUpdate): Promise<HttpResponse<any>> {
    let p = mcqUpdate;
    const response = await this.http
      .post<HttpResponse<any>>(this.endpoint.McqPostURL, p, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  getAllTimetables() {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getAllTimetables,
      this.options
    );
  }

  async timetableEdit(
    timetable,
    timetableId: string
  ): Promise<HttpResponse<any>> {
    let header = new HttpHeaders();
    header = Authenticationservice.authorizeHeader(header);

    const response = await this.http
      .put<HttpResponse<any>>(
        this.endpoint.updateTimetableURL + timetableId,
        timetable,
        {
          observe: 'response',
        }
      )
      .toPromise();
    return response;
  }
  //user/getAllProfiles
  getAllProfiles() {
    return this.http.get<HttpResponse<any>>(
      this.endpoint.getAllProfiles,
      this.options
    );
  }
  /***********************************delete-********************/
  async deleteTimetable(timetableId: String) {
    const response = await this.http
      .delete<HttpResponse<any>>(this.endpoint.deleteTimetable + timetableId, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }

  async deleteSchoolById(id: String) {
    const response = await this.http
      .delete<HttpResponse<any>>(this.endpoint.deleteSchoolById + id, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  async deleteBoard(Id: String) {
    const response = await this.http
      .delete<HttpResponse<any>>(this.endpoint.deleteBoard + Id, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  async deleteGrade(Id: String) {
    const response = await this.http
      .delete<HttpResponse<any>>(this.endpoint.deleteGrade + Id, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  async deleteStream(Id: String) {
    const response = await this.http
      .delete<HttpResponse<any>>(this.endpoint.deleteStream + Id, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  async deleteSubject(Id: String) {
    const response = await this.http
      .delete<HttpResponse<any>>(this.endpoint.deleteSubject + Id, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  async deleteLesson(Id: String) {
    const response = await this.http
      .delete<HttpResponse<any>>(this.endpoint.deleteLesson + Id, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }
  async deleteTopic(Id: String) {
    const response = await this.http
      .delete<HttpResponse<any>>(this.endpoint.deleteTopic + Id, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }

  async deletevideoLink(Id: String) {
    const response = await this.http
      .delete<HttpResponse<any>>(this.endpoint.deletevideoLink + Id, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }

  async deletePackageCategory(Id: String) {
    const response = await this.http
      .delete<HttpResponse<any>>(this.endpoint.deletePackageCategory + Id, {
        observe: 'response',
      })
      .toPromise();
    return response;
  }


  async deleteMcq(id: string) {
    const response = await this.http
    .delete<HttpResponse<any>>(this.endpoint.deleteMcq + id, {
      observe: 'response',
    })
    .toPromise();
  return response;
  }

  async deletePackage(id: string) {
    const response = await this.http
    .delete<HttpResponse<any>>(this.endpoint.deletePackage + id, {
      observe: 'response',
    })
    .toPromise();
  return response;
  }

  /**
   * set get school data
   */
  setSchoolData(data: any) {
    this.updateSchooldata = data;
  }
  getSchoolData() {
    return this.updateSchooldata;
  }

  setupdateSchoolData(data: any) {
    this.updatecondition = data;
  }
  getupdateSchoolData() {
    return this.updatecondition;
  }

  //   setSaveSchoolValues(data) {
  //     debugger
  //     this.setSaveSchool=data
  //   }
  // getSaveSchoolValues() {
  //   return this.setSaveSchool
  // }

  getProfiles() {
    this.headers = Authenticationservice.authorizeHeader(this.headers);
    return this.http.get<HttpResponse<any>>(this.endpoint.getAllProfiles, {
      headers: this.headers,
    });
  }

  editProfile(statusUpdate, userId) {
    this.headers = Authenticationservice.authorizeHeader(this.headers);
    return this.http.put<HttpResponse<any>>(
      this.endpoint.profileEdit + userId,
      statusUpdate,
      { headers: this.headers }
    );
  }
}
