import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'student',
    loadChildren: () =>
      import('./modules/school-students/school-students.module').then(
        (m) => m.SchoolStudentsModule
      ),
  },
  {
    path: 'super-admin',
    loadChildren: () =>
    import('./modules/super-admin/super-admin.module').then(
      (m) => m.SuperAdminModule
    )
  },
  {
    path: 'school-admin',
    loadChildren: () =>
    import('./modules/school-admin/school-admin.module').then(
      (m) => m.SchoolAdminModule
    )
  },
//  {
//        path: 'terms-and-conditions',
//        component: TemsandconditionsComponent
//        },
//       {
//      path: 'contactus',
//      component: ContactusComponent
//    }
  // {
  //   path: 'home',
  //   loadChildren: () => import('../app/UserSide/components/homeuser/homeuser.module').then(m => m.HomeUserModule)
  // },


  // {
  //   path: 'lessons',
  //   component: ShowlessionsComponent,
  // },
  // {
  //   path: 'lessonvideo/:item',
  //   component: LessonsvideopageComponent ,
  // },
  // {
  //   path: 'lessonvideo/:item/:topicId',
  //   component: LessonsvideopageComponent ,
  // },
  // {
  //   path: 'schoolstudentpayment',
  //   component: SchoolstudentpaymentComponent ,
  // },
  //  { path: 'dashboard',
  //   loadChildren: () => import('./Super-Admin/super-adminsidebar/super-adminsidebar.module').then(m =>
  //     m.SuperAdminSidesbarModule),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
