import { SchoolMaterialsService } from './../../../../core/services/school-materials/school-materials.service';
import { Subscription } from 'rxjs';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RegistrationUserDetailDialogComponent } from '../registration-user-detail-dialog/registration-user-detail-dialog.component';
import { ApiService } from 'src/app/core/services/ApiService/apiService';
import { AlertService } from 'src/app/core/services/alert/alert.service';
import { Board, Grade, Stream } from 'src/app/core/models/board.model';

@Component({
  selector: 'app-registerselectionboard',
  templateUrl: './registration-select-board-dialog.component.html',
  styleUrls: ['./registration-select-board-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegistrationSelectBoardDialogComponent
  implements OnInit, OnDestroy {
  boardList: Board[] = [];
  gradeList: Grade[] = [];
  streamList: Stream[] = [];

  selectedBoard: Board;
  selectedGrade: Grade = null;
  selectedStream: Stream = null;

  private subscriptions = new Subscription();

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<RegistrationSelectBoardDialogComponent>,
    private alertService: AlertService,
    private schoolMaterialService: SchoolMaterialsService
  ) {}

  ngOnInit(): void {
    this.getAllBoards();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getAllBoards() {
    this.subscriptions.add(
      this.schoolMaterialService.getAllBoards().subscribe((boards) => {
        let boardList: Board[] = boards;
        this.boardList = boardList.filter((board) => {
          return board.categoryId;
        });
      })
    );
  }

  onClickSelectBoard(board) {
    this.selectedBoard = board;
    this.getGradesByBoardId(board._id);
  }

  onClickGrade(grade: Grade) {
    this.selectedGrade = grade;
    this.getStreamsForGrade(grade._id);
  }

  getGradesByBoardId(boardId: string) {
    this.subscriptions.add(
      this.schoolMaterialService.getGradesByBoardId(boardId).subscribe(
        (res) => (this.gradeList = res),
        (err) => {
          let errMessage = 'Failed to load';
          this.gradeList = [];
          this.streamList = [];
          if (err.message) errMessage = err.message;
          this.alertService.error(errMessage);
        }
      )
    );
  }

  getStreamsForGrade(gradeId: string) {
    this.subscriptions.add(
      this.schoolMaterialService.getStreamsByGradeId(gradeId).subscribe(
        (streams: Stream[]) => {
          this.streamList = streams;
        },
        (err) => {
          this.streamList = [];
        }
      )
    );
  }

  onClickNext() {
    let boardDetails: any = {
      board: this.selectedBoard.board,
      boardId: this.selectedBoard._id,
      grade: this.selectedGrade.grade,
      gradeId: this.selectedGrade._id,
    };
    if (this.selectedStream) {
      boardDetails.stream = this.selectedStream.streamName;
      boardDetails.streamId = this.selectedStream._id;
    }
    this.dialogRef.close();
    const dialogRef = this.dialog.open(RegistrationUserDetailDialogComponent, {
      maxWidth: 700,
      minWidth: 250,
      width: '500px',
      data: boardDetails,
    });
  }

  isSelectionValid(): boolean {
    if (this.streamList.length > 0) {
      if (this.selectedStream) return true;
    } else if (this.gradeList.length > 0) {
      if (this.selectedGrade) return true;
    }
    return false;
  }
}
