import { AdminLoginComponent } from './pages/admin-login/admin-login.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LandingRoutingModule } from './landing-routing.module';
import { LoginDialogComponent } from './components/login-dialog/login.dialog.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { LandingpageComponent } from './pages/landingpage/landingpage.component';
import { RegistrationStudentCodeDialogComponent } from './components/registration-student-code-dialog/registration-student-code-dialog.component';
import { RegistrationMobilenoDialogComponent } from './components/registration-mobileno-dialog/registration-mobileno-dialog.component';
import { RegistrationOtpVerificationDialogComponent } from './components/registration-otp-verification-dialog/registration-otp-verification-dialog.component';
import { RegistrationSelectBoardDialogComponent } from './components/registration-select-board-dialog/registration-select-board-dialog.component';
import { RegistrationUserDetailDialogComponent } from './components/registration-user-detail-dialog/registration-user-detail-dialog.component';

import { LandingRootComponent } from './pages/landing-root/landing-root.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { TemsandconditionsComponent } from './pages/temsandconditions/temsandconditions.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { MarketingComponent } from './pages/marketing/marketing.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PricingComponent } from './pages/pricing/pricing.component';


@NgModule({
  declarations: [
    LandingRootComponent,
    LandingpageComponent,
    ContactusComponent,
    TemsandconditionsComponent,
    PrivacypolicyComponent,
    MarketingComponent,
    AdminLoginComponent,
    LoginDialogComponent,
    RegistrationStudentCodeDialogComponent,
    RegistrationMobilenoDialogComponent,
    RegistrationOtpVerificationDialogComponent,
    RegistrationSelectBoardDialogComponent,
    RegistrationUserDetailDialogComponent,
    PricingComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    BrowserAnimationsModule,
    LandingRoutingModule
  ]
})
export class LandingModule { }
