import { HttpHeaders } from "@angular/common/http";

import { Injectable } from "@angular/core";
import { AppConfig } from "src/app/config/app-config";
import { StorageProvider } from "../storage.provider";
// import { AppConfig } from "../config/app-config";

@Injectable({
  providedIn: 'root'
})
export class ApiProvider {

  static AuthorizationHeaderName = 'Authorization';
  static AuthorizationHeaderTokenType = 'Bearer';
  static AuthorizationHeaderValuePlaceholder = '-'

  private static apiBaseUrl = null;

  static getPlaceholderAuthHeader(headers?: HttpHeaders) {
    if (!headers) headers = new HttpHeaders();
    headers = headers.set(this.AuthorizationHeaderName,
      'Bearer ' + StorageProvider.getUserLoginToken());
    return headers;
  }

  static apiBaseUrlify(path: string) {
    const baseUrl = this.apiBaseUrl;
    return `${baseUrl}/${path}`;
  }

  static isApiUrl(url: string) {
    if (url.startsWith(this.apiBaseUrl)) return true;
  }

  static initialize() {
    this.apiBaseUrl = AppConfig.getApiBaseUrl();
  }

  userApiUrl = 'user';
  adminApiUrl = 'admin';

  constructor() {
    this.initialize();
  }

  initialize() {
    this.userApiUrl = ApiProvider.apiBaseUrlify(this.userApiUrl);
    this.adminApiUrl = ApiProvider.apiBaseUrlify(this.adminApiUrl);
  }




}
