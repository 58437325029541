import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/core/services/alert/alert.service';
import { UserAuthService } from 'src/app/core/services/user-auth/user-auth.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss'],
})
export class AdminLoginComponent implements OnInit, OnDestroy {
  loginForm = new FormGroup({
    username: new FormControl(null, Validators.required),
    password: new FormControl(null, Validators.required),
  });

  private userType = 'SuperAdmin';

  private subscriptions = new Subscription();

  constructor(
    private authService: UserAuthService,
    private router: Router,
    private alertService: AlertService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSubmit() {
    this.subscriptions.add(
      this.authService
        .authenticate(
          this.loginForm.value.username,
          this.loginForm.value.password,
          this.userType,
          false
        )
        .subscribe(
          () => this.successHandler(),
          (err) => this.errorHandler(err)
        )
    );
  }

  successHandler() {
    this.router.navigate(['/super-admin']);
    this.alertService.success('Successfully Logged in!');
  }

  errorHandler(error) {
    let errorMessage = 'Failed to login';
    if (error.error?.err) errorMessage = error.error?.err;
    this.alertService.error(`${errorMessage}`);
  }
}
