import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/core/services/user-auth/user-auth.service';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { CommonAlertComponentComponent } from '../common-alert-component/common-alert-component.component';
@Component({
  selector: 'app-resetpassword-component',
  templateUrl: './resetpassword-component.component.html',
  styleUrls: ['./resetpassword-component.component.scss']
})
export class ResetpasswordComponentComponent implements OnInit {
 Resetpasswordform:FormGroup;
  token:string;
    auth: { success: boolean; token: string; };
  constructor(
  
    private activatedRoute: ActivatedRoute,
    private authservice: UserAuthService,
    private dialog: MatDialog,) {
    this.Resetpasswordform = new FormGroup({
      phoneNumber: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(4)
      ])
    });
  }
  ngOnInit(): void {
      this.activatedRoute.params.forEach((params: Params) => {
  //    console.log('PARAMS', params);
      this.token = params.token;
      this.auth = {
       success:true,
        token: this.token
      };
      // console.log('token', this.token);
    });
   }
  async submit(Resetpasswordform){
   // debugger
    console.log(Resetpasswordform,"Resetpasswordform")
    this.authservice.resetpassword(Resetpasswordform).then(response=>{
      this.Resetpasswordform.reset()
         const dialogRef = this.dialog.open( CommonAlertComponentComponent , {
      data: {
        message: " Password Updated Successfully!",
        buttonText: {
          cancel: "Ok",
        },
      },
    });
    },error=>{console.log("error")})
}
}
