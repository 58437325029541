import { UserRegisterMethods } from './../../../../core/models/user.model';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RegistrationMobilenoDialogComponent } from '../registration-mobileno-dialog/registration-mobileno-dialog.component';
import { RegistrationOtpVerificationDialogComponent } from '../registration-otp-verification-dialog/registration-otp-verification-dialog.component';
import { ApiService } from 'src/app/core/services/ApiService/apiService';
import { UserAuthService } from 'src/app/core/services/user-auth/user-auth.service';
import { AlertService } from 'src/app/core/services/alert/alert.service';
import { error } from 'protractor';
@Component({
  selector: 'app-registration-student-code-dialog',
  templateUrl: './registration-student-code-dialog.component.html',
  styleUrls: ['./registration-student-code-dialog.component.scss'],
})
export class RegistrationStudentCodeDialogComponent implements OnInit {

  usercode: string;
  mobileNo: string;

  constructor(
    private authService: UserAuthService,
    private alertService: AlertService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<RegistrationStudentCodeDialogComponent>
  ) {}

  ngOnInit(): void {}

  onRegisterClick() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(RegistrationMobilenoDialogComponent, {
      maxWidth: 700,
      minWidth: 250,
      width: '500px',
    });
  }

  onClickContinue() {
    this.authService.verifyStudentCode(this.usercode).subscribe(
      (successReponse) => this.otpSuccessHandler(successReponse),
      (error) => this.otpErrorHandler(error)
    );
  }

  otpSuccessHandler(response) {
    if (response.success) {
      let mobileNo: string = response.otp.phoneNumber;
      this.mobileNo = mobileNo;
      let trimmedPhoneNo = '******' + mobileNo.slice(-4);
      this.alertService.success(`${response.message} ${trimmedPhoneNo}`);
      const dialogRef = this.dialog.open(
        RegistrationOtpVerificationDialogComponent,
        {
          maxWidth: 700,
          minWidth: 250,
          width: '500px',
          data: { mobileNo: this.mobileNo, directedFrom: UserRegisterMethods.STUDENT_CODE },
        }
      );
    }
  }
  otpErrorHandler(error) {
    this.alertService.error(error.message);
  }
}
