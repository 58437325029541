import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from './shared/shared.module';
import { LandingModule } from './modules/landing/landing.module';
import { Authorization } from './core/models/auth.provider';
// import { ContactusComponent } from './modules/landing/pages/contactus/contactus.component';
// import { TemsandconditionsComponent } from './modules/school-students/pages/temsandconditions/temsandconditions.component';
// import { PrivacypolicyComponent } from './modules/school-students/pages/privacypolicy/privacypolicy.component';
import { ForgotpasswordComponentComponent } from './modules/landing/components/forgotpassword-component/forgotpassword-component.component';
import { OtpverificationComponentComponent } from './modules/landing/components/otpverification-component/otpverification-component.component';
import { ResetpasswordComponentComponent } from './modules/landing/components/resetpassword-component/resetpassword-component.component';
import { CommonAlertComponentComponent } from './modules/landing/components/common-alert-component/common-alert-component.component';

@NgModule({
  declarations: [
    AppComponent,
    // ContactusComponent,
    // TemsandconditionsComponent,
    // PrivacypolicyComponent,
    ForgotpasswordComponentComponent,
    OtpverificationComponentComponent,
    ResetpasswordComponentComponent,
    CommonAlertComponentComponent,
  ],
  imports: [
    BrowserModule,

    BrowserAnimationsModule,
    HttpClientModule,

    SharedModule,
    LandingModule,

    AppRoutingModule,
  ],

  providers: [Authorization],
  bootstrap: [AppComponent],
})
export class AppModule {}
