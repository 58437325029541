import { StorageProvider } from './../../storage.provider';
import { ApiProvider } from 'src/app/core/http/api-provider';
import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User, UserProfile } from '../../models/user.model';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../../models/api-response';
import { Authorization } from '../../models/auth.provider';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrl = '/user';

  currentUser$ = new BehaviorSubject<User>(null);

  constructor(
    private http: HttpService,
    private apiProvider: ApiProvider,
    private authProvider: Authorization
  ) {
    this.apiUrl = apiProvider.userApiUrl;
  }

  setCurrentUser(user: User) {
    this.currentUser$.next(user);
  }

  getCurrentUser(force?: boolean): Observable<User> {
    if (this.currentUser$.value && !force) {
      return of(this.currentUser$.getValue());
    }

    let currentUserToken = StorageProvider.getUserLoginToken();
    let currentUserId = this.authProvider.getUserIdFromToken(currentUserToken);
    let currentUserType = this.authProvider.getUserTypeFromToken(currentUserToken);
    StorageProvider.setUserType(currentUserType);

    if (currentUserId == undefined) {
      return of(null);
    }


    return this.http
      .get(`${this.apiUrl}/getUser/${currentUserId}`, {
        headers: ApiProvider.getPlaceholderAuthHeader(),
      })
      .pipe(
        map((userResponse: ApiResponse) => {

          if (!userResponse.data) {
            console.log("Userresponse data is null!!")
            this.setCurrentUser(null);
            return null;
          }
          let user = userResponse.data;
          this.setCurrentUser(user);

          return user;
        })
      );
  }

  getUserByUserId(userId: string) {
    return this.http
    .get(`${this.apiUrl}/getUser/${userId}`, {
      headers: ApiProvider.getPlaceholderAuthHeader(),
    })
    .pipe(
      map((userResponse: ApiResponse) => {
        if (!userResponse.message || !userResponse.data) {
          this.setCurrentUser(null);
          return null;
        }
        let user = userResponse.data;
        this.setCurrentUser(user);
        return user;
      })
    );
  }

  createUserProfile(userProfile: UserProfile) {
    let currentUserToken = StorageProvider.getUserLoginToken();

    let userId = this.authProvider.getUserIdFromToken(currentUserToken);

    if (!userId) return of(null);

    return this.http.post(
      `${this.apiUrl}/createProfile/${userId}`,
      userProfile,
      {
        headers: ApiProvider.getPlaceholderAuthHeader(),
      }
    ).pipe(
      map((response: ApiResponse) => {
        return response.data;
      })
    );
  }

  getCurrentUser$() {
    return this.currentUser$.asObservable();
  }

  getCurrentUserType() {
    return StorageProvider.getUserType();
  }
}
