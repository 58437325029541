export enum UserRegisterMethods {
  STUDENT_CODE = 'STUDENT_CODE',
  MOBILE_NO = 'MOBILE_NO',
}

export enum UserType {
  SCHOOL_STUDENT = 'SCHOOL_STUDENT',
  PUBLIC_STUDENT = 'PUBLIC_STUDENT',
};

export class User {
  id: string;
  phoneNumber: string;
  email: string;
  profiles: string[];
  board: string;
  boardId: string;
  city: string;

  grade: string;
  gradeId: string;
  name: string;
  stream: string;
  streamId: string;

  profileId: string;
}

export interface UserProfile {
  name: string;
  email: string;
  board: string;
  boardId: string;
  grade: string;
  gradeId: string;
  stream?: string;
  streamId?: string;
  city: string;
  password: string;
}
