import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert/alert.service';
import { Authenticationservice } from 'src/app/core/services/Auth/authenticationService';
import { ForgotpasswordComponentComponent } from '../forgotpassword-component/forgotpassword-component.component';
import { UserAuthService } from 'src/app/core/services/user-auth/user-auth.service';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-login',
  templateUrl: './login.dialog.component.html',
  styleUrls: ['./login.dialog.component.scss'],
})
export class LoginDialogComponent implements OnInit, OnDestroy {
  userEmailOrPhone: string;
  password: string;
  userType: string;

  private subscriptions = new Subscription();

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private authService: UserAuthService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<LoginDialogComponent>
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onLoginClick() {
    this.subscriptions.add(
    this.authService
      .authenticate(this.userEmailOrPhone, this.password,this.userType ,false)
      .subscribe(
        () => this.successHandler(),
        (errorResponse) => this.errorHandler(errorResponse)
      ));
  }

  successHandler() {
    this.dialogRef.close();
    this.router.navigate(['/student', 'home']);
    this.alertService.success('Successfully Logged in!');
  }

  errorHandler(error) {
    let errorMessage = 'Failed to login';
    if (error.error?.message) errorMessage = error.error?.message;
    this.alertService.error(`${errorMessage}`);
  }
   openForgotpasswordDialog() {
    const dialogRef = this.dialog.open(ForgotpasswordComponentComponent, {
      disableClose: true,
      maxWidth: 800,
      // minWidth: 250,
      width: '600px',
      height:'422px',
       panelClass: 'custom-modalbox-password'
    });
  }
}
