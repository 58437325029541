import { UserService } from './../../../core/services/user/user.service';
import { Subscription, Subject } from 'rxjs';
import { SchoolMaterialsService } from './../../../core/services/school-materials/school-materials.service';
import { TuitionDetailsComponent } from './../../components/tuition-details/tuition-details.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/core/models/user.model';
import { SubjectSchool } from 'src/app/core/models/subject.model';

@Component({
  selector: 'app-tuition',
  templateUrl: './tuition.component.html',
  styleUrls: ['./tuition.component.scss']
})
export class TuitionComponent implements OnInit, OnDestroy {

  currentUser: User = null;
  subjectList: SubjectSchool[] = [];

  url: string = 'https://www.appsophia.com/edu/api/';

  private subscriptions = new Subscription();

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private schoolMaterialService: SchoolMaterialsService
  ) { }

  ngOnInit() {
    this.subscriptions.add(this.userService.currentUser$.subscribe((user: User) => {
      if(user) {
        this.currentUser = user;
        this.getSubjects();
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onClickSubject(subject: SubjectSchool) {
    this.dialog.open(TuitionDetailsComponent,{
      maxWidth: 800,
      minWidth: 250,
      width: '700px',
      data: {
        subject: subject,
        subjectName: subject.subjectName,
        boardName: this.currentUser.board,
        gradeName: this.currentUser.grade,
        streamName: this.currentUser.stream
      }
    });
  }

  getSubjects() {
    this.subscriptions.add(
      this.schoolMaterialService
        .getSubjectsByGradeIdAndStream(this.currentUser.gradeId, this.currentUser.stream)
        .subscribe((subjects) => {
          subjects.forEach((element) => {
            element.image_path = this.url + element.image_path;
          });
          this.subjectList = subjects;
        })
    );
  }

}
