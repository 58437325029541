import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
// import { EduwebendpointService } from '../Endpoint/eduwebendpoint.service';

import { map, catchError, mergeMap } from 'rxjs/operators';
import { ApiProvider } from 'src/app/core/http/api-provider';


interface AuthResponse {
  data: any;
  success: boolean;
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class Authenticationservice {
  static readonly authorizationKey: string = 'Authorization';
  static readonly tokenStorageKey = 'currentUserToken';
  static readonly userEmailStorageKey = 'currentUserUseremail';
  static readonly userNameStorageKey = 'currentUserUsername';
  static readonly userRoleStorageKey = 'currentUserRole';
  static readonly userPhoneNumberStorageKey = 'currentUserPhoneNumber';

  static authorizationValue: string;
  private urlAuthenticate = 'https://www.appsophia.com/edu/api/user/authenticate';
  isSignedIn = new BehaviorSubject<boolean>(false);

  private keepMeLoggedIn: boolean;
  private headers = new HttpHeaders({ 'Content-type': 'application/json' });
  options = { headers: this.headers };

  private apiUrl = '/user';

  constructor(
    private http: HttpClient,
    // private endpoint: EduwebendpointService,
    private apiProvider: ApiProvider,

  ) {
    this.apiUrl = apiProvider.userApiUrl;
  }






  //=======Get auth token from session strorage=========//
  static getAuthToken(): string {
    const sessionToken = sessionStorage.getItem(Authenticationservice.tokenStorageKey);
    const persistentToken = localStorage.getItem(Authenticationservice.tokenStorageKey);
    if (sessionToken) {
      return sessionToken;
    } else if (persistentToken) {
      sessionStorage.setItem(Authenticationservice.tokenStorageKey, persistentToken);
      sessionStorage.setItem(Authenticationservice.userEmailStorageKey, localStorage.getItem(Authenticationservice.userEmailStorageKey));
      return persistentToken;
    }
    return '';
  }
  errorHandler(errorResponse: HttpErrorResponse) {
    let errMsg: string;
    if (errorResponse.error instanceof ErrorEvent) {
      const error = errorResponse.error;
      console.log("ERROR",errorResponse.error);

    } else {
    }

    return throwError(JSON.stringify(errorResponse.status));

  }

  login(login_info) {

    this.headers = Authenticationservice.authorizeHeader(this.headers);
    return this.http.post<AuthResponse>(this.urlAuthenticate, JSON.stringify(login_info), { headers: this.headers })
    .pipe(
      map(response => this.loginHandler(response)),
      catchError(this.errorHandler)
    );
  }

 /* ==========to handle Login Handler error ======== */
 otpErrorHandler(errorResponse: HttpErrorResponse) {
  console.log("errorResponse",errorResponse)
  if(errorResponse.status==409){
    if(errorResponse.error.msg=="Not Found"){
      alert("Wrong Credentials,User Name Not matched with any Account, Please Check")
  }

  }
  if(errorResponse.status==500){
    alert("Something Went Wrong,Please try Again")
  }
  let errMsg: string;
  if (errorResponse.error instanceof ErrorEvent) {
    const error = errorResponse.error;
    console.log(errorResponse.error);

  } else {
  }

  return throwError(JSON.stringify(errorResponse.status));

}


/* ======= for save data for future use(remember me)========= */
loginHandlerOnOtpVerification(response): boolean {
  console.log(response);
   const token: any = response.data.token;

    const currentUserDetails = this.getUserDetailsFromToken(token);
    console.log("CUREENT USER",currentUserDetails)
    sessionStorage.setItem(Authenticationservice.tokenStorageKey, token);
     sessionStorage.setItem(Authenticationservice.userPhoneNumberStorageKey, currentUserDetails.phoneNumber);

    this.setAuthHeaderValue();
    return response;


}

/* ======= for save data for future use(remember me)========= */
loginHandler(response: AuthResponse): boolean {
  console.log(response);
  const authResponse: any = response;
  if (authResponse.success) {
console.log("authResponse.success",authResponse.success);
    const currentUserDetails = this.getUserDetailsFromToken(authResponse.token);
    console.log(currentUserDetails,"currentUserDetails")
    sessionStorage.setItem(Authenticationservice.tokenStorageKey, authResponse.data.token);
    sessionStorage.setItem("phoneNumber", currentUserDetails.phoneNumber);
    sessionStorage.setItem("email", currentUserDetails.email);
    sessionStorage.setItem("id", currentUserDetails.id);
    sessionStorage.setItem("defaultProfile", currentUserDetails.defaultProfile);

    if (this.keepMeLoggedIn) {
      localStorage.setItem(Authenticationservice.tokenStorageKey, authResponse.token);

    }

    this.setAuthHeaderValue();
    this.checkLoggedIn();
    return true;
  } else {
    this.checkLoggedIn();
    this.logout();
  }
  return false;
}



  setAuthHeaderValue() {
    Authenticationservice.authorizationValue =
      'Bearer ' + Authenticationservice.getAuthToken();
    Authenticationservice.authorizeHeader(this.headers);
  }
  /*========get authorized header*/
  static authorizeHeader(headers: HttpHeaders) {
    Authenticationservice.authorizationValue =
      'Bearer ' + Authenticationservice.getAuthToken();
    if (!headers.has(Authenticationservice.authorizationKey)) {
      return headers.append(
        Authenticationservice.authorizationKey,
        Authenticationservice.authorizationValue
      );
    } else {
      return headers.set(
        Authenticationservice.authorizationKey,
        Authenticationservice.authorizationValue
      );
    }
  }


  getUserDetailsFromToken(token: string): { phoneNumber:string,id:string,email:string,defaultProfile:string} {
    if (!token) {
      token = Authenticationservice.getAuthToken();

    }
    console.log("TOKEN",token)
    const payloadStart = token.indexOf('.') + 1;
    const payloadEnd = token.lastIndexOf('.');
    let payload = token.substring(payloadStart, payloadEnd);


    if (payload.length === 0) { } else {
      payload = atob(payload);
      const jsonPayload: any = JSON.parse(payload);
      console.log("Payload",jsonPayload);
    //  this.userPhoneNumber.next(jsonPayload.phoneNumber);
      return {phoneNumber:jsonPayload.phoneNumber,id:jsonPayload.id,email:jsonPayload.email,defaultProfile:jsonPayload.defaultProfile};
    }
  }
  getUserDetailsFromUserId(token: string): { user_id:string} {
    if (!token) {
      token = Authenticationservice.getAuthToken();

    }
    const payloadStart = token.indexOf('.') + 1;
    const payloadEnd = token.lastIndexOf('.');
    let payload = token.substring(payloadStart, payloadEnd);


    if (payload.length === 0) { } else {
      payload = atob(payload);
      const jsonPayload: any = JSON.parse(payload);
      console.log("Payload",jsonPayload);
    //  this.user_id.next(jsonPayload.user_id);
      return {user_id:jsonPayload.user_id };
    }
  }

  private checkLoggedIn() {
    if (sessionStorage.getItem(Authenticationservice.tokenStorageKey)) {
      this.getUserDetailsFromToken(sessionStorage.getItem(Authenticationservice.tokenStorageKey));


      this.isSignedIn.next(true);

      return;

    } else if (localStorage.getItem(Authenticationservice.tokenStorageKey)) {
      console.log('Local storage true');
      this.isSignedIn.next(true);

      return ;
    }
    console.log('Not signed in!');
    this.isSignedIn.next(false);


    return;
    // return false;
  }
  logout() {
    this.isSignedIn.next(false);


    this.keepMeLoggedIn = false;
    sessionStorage.removeItem(Authenticationservice.tokenStorageKey);

    this.setAuthHeaderValue();

  }

}
