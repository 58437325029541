import { error } from 'protractor';
import { Subscription } from 'rxjs';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RegistrationSelectBoardDialogComponent } from '../registration-select-board-dialog/registration-select-board-dialog.component';
import { UserRegisterMethods } from 'src/app/core/models/user.model';
import { UserAuthService } from 'src/app/core/services/user-auth/user-auth.service';
import { AlertService } from 'src/app/core/services/alert/alert.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-registration-otp-verification-dialog',
  templateUrl: './registration-otp-verification-dialog.component.html',
  styleUrls: ['./registration-otp-verification-dialog.component.scss'],
})
export class RegistrationOtpVerificationDialogComponent
  implements OnInit, OnDestroy {
  otp: string;
  registeredVia: string;
  mobileNo: string;

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private authService: UserAuthService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<RegistrationOtpVerificationDialogComponent>,
    private userService: UserService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: { mobileNo: string; directedFrom: string }
  ) {
    this.mobileNo = data.mobileNo;
    this.registeredVia = data.directedFrom;
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

   onClickVerifyOTP() {
    if (this.registeredVia == UserRegisterMethods.MOBILE_NO) {
      //PUBLIC
      this.subscriptions.add(
        this.authService.verifyMobileOtp(this.otp, this.mobileNo).subscribe(
          (res) => this.openSelectBoardDialog(res),
          (err) => this.alertService.error(`${err.error?.message}`)
        )
      );
    } else if (this.registeredVia == UserRegisterMethods.STUDENT_CODE) {
      //ADMIN
      this.subscriptions.add(
      this.authService.verifyStudentCodeOtp(this.otp, this.mobileNo).subscribe(
        (res) => this.onStudentCodeVerifySuccessHandler(res),
        (error) => this.alertService.error(error.message)
      )
      );
    }
  }

  openSelectBoardDialog(otpSuccessResponse) {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(RegistrationSelectBoardDialogComponent, {
      maxWidth: 800,
      minWidth: 250,
      width: '700px',
      data: otpSuccessResponse.data,
    });
  }

  onStudentCodeVerifySuccessHandler(res) {
    this.dialogRef.close();
    this.router.navigate(['/student','payment'])
  }
}
