import { Component, HostBinding, OnInit } from '@angular/core';
import { slideInAnimation } from './landing-route-animation';

@Component({
  selector: 'app-landing-root',
  templateUrl: './landing-root.component.html',
  styleUrls: ['./landing-root.component.scss'],
  animations: [slideInAnimation]
})
export class LandingRootComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
