import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  dialogRef;
  confirmAction = new BehaviorSubject<boolean>(false);

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog
    ) { }

  success(message: string) {
    this.openAlertNotification(message, 'alert-success');
  }

  error(message: string, ) {
    this.openAlertNotification(message, 'alert-danger');
  }

  info(message: string, ) {
    this.openAlertNotification(message, 'alert-info');
  }

  warn(message: string) {
    this.openAlertNotification(message, 'alert-warning')
  }

  // async confirm(message: string, confirmText: string, cancelText: string) {

  //   const confirmDialog = this.dialog.open(AlertConfirmationDialogComponent, {
  //     data:{
  //       message: message,
  //       buttonText: {
  //         ok: confirmText,
  //         cancel: cancelText
  //       }
  //     }
  //   });

  //    confirmDialog.afterClosed().toPromise().then(confirm => {
  //      this.confirmAction.next(confirm);
  //      this.confirmAction.next(false);
  //      setTimeout(() => {
  //       this.confirmAction.next(false);
  //      }, 1000)
  //    });
  // }

  openAlertNotification( message, className, action?) {
    let actions;
    if(action) {actions = action;};
    this.snackBar.open(message, actions, {
      data: message,
      duration: 4000,
      panelClass: className,
      verticalPosition: 'top'
    });
  }

}
